import './index.css';

import AppRouter from './router/AppRouter';
import { Provider } from 'react-redux'
import React from 'react'
import ReactDOM from 'react-dom'
import { createStore } from 'redux';
import reducers from './reducers';

ReactDOM.render(
    <Provider store={createStore(
        reducers,
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )}>
        <AppRouter />
    </Provider>
    , document.getElementById("root"));