import React from 'react';

const InfoTextBlock = ({ text, distance, usingMetric }) => {
    const units = usingMetric ? 'Kms' : 'Miles';
    const totalDistance = usingMetric ? distance.kms : distance.miles;

    return (
        <div>
            <h5 className="mr-4 font-sans text-2xl text-cyan-900">{text}</h5>
            <h5 className="font-sans text-xl">{totalDistance}{units}</h5>
        </div>
    );
};

export default InfoTextBlock;
