import {
    americaLaps,
    burjLaps,
    cape2CairoLaps,
    earthLaps,
    moonLaps,
    mountEverestLaps,
    poolLaps,
    southKoreaLaps,
    tourLaps,
    trackLaps
} from '../utils/functions';

import HeaderBlock from './HeaderBlock';
import InfoImageBlock from './InfoImageBlock';
import InfoTextBlock from './InfoTextBlock';
import React from 'react';
import SelectionBlock from './SelectionBlock';
import burj from '../assets/burj.jpeg';
import cape2Cairo from '../assets/cape2Cairo.jpg'
import earth from '../assets/earth.jpg';
import moon from '../assets/moon.jpeg';
import mountEverest from '../assets/mountEverest.jpeg';
import pool from '../assets/pool.jpeg';
import seoul from '../assets/seoul.jpeg';
import tourDeFrance from '../assets/tourDeFrance.jpeg';
import track from '../assets/track.jpg';
import usa from '../assets/usa.jpeg';
import { useSelector } from 'react-redux';

const BlockList = () => {
    const userActivities = useSelector((state) => state.userActivities);
    const metric = useSelector((state) => state.metric);
    const sports = useSelector((state) => state.sports);
    const totalDistance = useSelector((state) => state.totalDistance);

    const totalRunDisplay = () => {
        if (sports.includes('running')) {
            return (
                <InfoTextBlock
                    text={'Running'}
                    distance={userActivities.runTotal}
                    usingMetric={metric}
                />
            );
        }
    };

    const totalRideDisplay = () => {
        if (sports.includes('riding')) {
            return (
                <InfoTextBlock
                    text={'Riding'}
                    distance={userActivities.rideTotal}
                    usingMetric={metric}
                />
            );
        }
    };

    const totalSwimDisplay = () => {
        if (sports.includes('swimming')) {
            return (
                <InfoTextBlock
                    text={'Swimming'}
                    distance={userActivities.swimTotal}
                    usingMetric={metric}
                />
            );
        }
    };

    // If no sport selected, return "Choose a sport" message
    const pageDisplay = () => {
        if (sports.length === 0) {
            return (
                <h1 className="info-title mt-5 content-body">
                    Select a Sport to Continue
                </h1>
            );
        } else {
            return (
                <>
                    {totalRunDisplay()}
                    {totalRideDisplay()}
                    {totalSwimDisplay()}
                    <InfoImageBlock
                        imageSRC={track}
                        number={trackLaps(totalDistance)}
                        text={'Laps on a Track'}
                        usingMetric={metric}
                        kms={'(400 Meters)'}
                        miles={'(0.25 Miles)'}
                    />
                    <InfoImageBlock
                        imageSRC={pool}
                        number={poolLaps(totalDistance)}
                        text={'Olympic Swimming Pools'}
                        usingMetric={metric}
                        kms={'(50 Meters)'}
                        miles={'(0.03 Miles)'}
                    />
                    <InfoImageBlock
                        imageSRC={tourDeFrance}
                        number={tourLaps(totalDistance)}
                        text={'Tour De France Finishes'}
                        usingMetric={metric}
                        kms={'(3,470 Kms)'}
                        miles={'(2,156 Miles)'}
                    />
                    <InfoImageBlock
                        imageSRC={burj}
                        number={burjLaps(totalDistance)}
                        text={'Trips Up and Down the Burj Khalifa'}
                        usingMetric={metric}
                        kms={'(830 Meters)'}
                        miles={'(0.52 Miles)'}
                    />
                    <InfoImageBlock
                        imageSRC={mountEverest}
                        number={mountEverestLaps(totalDistance)}
                        text={'Climbs up Mount Everest'}
                        usingMetric={metric}
                        kms={'(8,849 Meters)'}
                        miles={'(5.49 Miles)'}
                    />
                    <InfoImageBlock
                        imageSRC={usa}
                        number={americaLaps(totalDistance)}
                        text={'Times Across America'}
                        usingMetric={metric}
                        kms={'(4,662 Kms)'}
                        miles={'(2,896 Miles)'}
                    />
                    <InfoImageBlock
                        imageSRC={seoul}
                        number={southKoreaLaps(totalDistance)}
                        text={'Times Across South Korea'}
                        usingMetric={metric}
                        kms={'(1,200 Kms)'}
                        miles={'(745 Miles)'}
                    />
                    <InfoImageBlock
                        imageSRC={earth}
                        number={earthLaps(totalDistance)}
                        text={'Around the Earth'}
                        usingMetric={metric}
                        kms={'(40,075 Kms)'}
                        miles={'(24,901 Miles)'}
                    />
                    <InfoImageBlock
                        imageSRC={moon}
                        number={moonLaps(totalDistance)}
                        text={'To the Moon'}
                        usingMetric={metric}
                        kms={'(384,400 Kms)'}
                        miles={'(238,855 Miles)'}
                    />
                          <InfoImageBlock
                        imageSRC={cape2Cairo}
                        number={cape2CairoLaps(totalDistance)}
                        text={'Times from Cape to Cairo'}
                        usingMetric={metric}
                        kms={'(10,882 Kms)'}
                        miles={'(6761.76 Miles)'}
                    />
                </> //10,882 6761.7613
            );
        }
    };

    return (
        <div className="flex justify-center">
            <div className="px-4">
                <HeaderBlock />
                <SelectionBlock />
                {pageDisplay()}
            </div>
        </div>
    );
};

export default BlockList;
