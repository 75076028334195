import React, { useState } from 'react';

import coffeeBike from '../assets/coffeeBike.png';

const Item = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="border rounded shadow-sm">
            <button
                type="button"
                aria-label="Open item"
                title="Open item"
                className="flex items-center justify-between w-full p-4 focus:outline-none"
                onClick={() => setIsOpen(!isOpen)}
            >
                <p className="text-lg font-medium">{title}</p>
                <div className="flex items-center justify-center w-8 h-8 border rounded-full">
                    <svg
                        viewBox="0 0 24 24"
                        className={`w-3 text-gray-600 transition-transform duration-200 ${isOpen ? 'transform rotate-180' : ''
                            }`}
                    >
                        <polyline
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            points="2,7 12,17 22,7"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
            </button>
            {isOpen && (
                <div className="p-4 pt-0">
                    <p className="text-gray-700">{children}</p>
                </div>
            )}
        </div>
    );
};

const Faq = () => {
    return (
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
                <div className="flex flex-col mb-16 sm:text-center">
                    <a href="/" className="mb-6 sm:mx-auto bg-white">
                        <div className="flex items-center justify-center w-28 h-28 rounded-full bg-white">
                            <img
                                src={coffeeBike}
                                alt="Cartoon person on laptop"
                            />
                        </div>
                    </a>
                    <div className="max-w-xl md:mx-auto sm:text-center lg:max-w-2xl">
                        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                            <span className="relative inline-block">
                                <span className="relative">Frequently</span>
                            </span>{' '}
                            asked questions
                        </h2>
                        <p className="text-base text-gray-700 md:text-lg"></p>
                    </div>
                </div>
                <div className="space-y-4">
                    <Item title="Do you store my Strava login details?">
                        No, actually we do not even has access to your username or password. This is all handled by Strava.com using the OAuth 2.0 flow and a token is returned that is used to update your activity with a pun.
                    </Item>
                    <Item title="What if my puns won't sync?">
                        Navigate to Strava and check if onlyPuns is linked as a
                        linked app{' '}
                        <a
                            href="https://www.strava.com/settings/apps"
                            className="text-orange-900"
                        >
                            https://www.strava.com/settings/apps
                        </a>
                    </Item>
                    <Item title="Which sports are renamed with puns?">
                        Cycling and running for starters but you can always
                        submit your favorite pun and we may expand in the
                        future.
                    </Item>
                    <Item title="How much does it cost?">
                        Nothing, this will always be free. If you would like to
                        support please donate as servers sadly are not free.
                    </Item>
                    <Item title="I changed the name of my ride and now it won't rename it with a pun?">
                        This is the expected behaviour, only unnamed activities
                        will be synced for example 'Morning ride'.
                    </Item>

                    <Item title="I have some great new puns, how do I submit them?">
                        Please submit any new puns using the form <a className="text-orange-900"
                            href='https://onlypuns.xyz/submit'>here</a>
                    </Item>
                </div>
            </div>
        </div>
    );
};

export default Faq;
