import {
    Link,
    Redirect,
    BrowserRouter as Router,
    Switch,
} from 'react-router-dom';
import React, { useState } from 'react';

import { useSelector } from 'react-redux';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const userProfile = useSelector((state) => state.userProfile);

    if (userProfile) {
        return (
            <div className="bg-gray-100">
                <nav className="py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
                    <div className="relative flex items-center justify-between">
                        <Link
                            to={userProfile ? '/' : '/login'}
                            aria-label="OnlyPuns"
                            title="OnlyPuns"
                            className="inline-flex items-center"
                        >
                            <svg
                                className="w-8 text-deep-gray-accent-400"
                                viewBox="0 0 24 24"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeMiterlimit="10"
                                stroke="currentColor"
                                fill="none"
                            >
                                <rect x="3" y="1" width="7" height="12" />
                                <rect x="3" y="17" width="7" height="6" />
                                <rect x="14" y="1" width="7" height="6" />
                                <rect x="14" y="11" width="7" height="12" />
                            </svg>
                            <span className="ml-2 text-xl font-bold tracking-wide text-orange-900">
                                OnlyPuns
                            </span>
                        </Link>
                        <div className="flex items-center hidden space-x-8 lg:flex">
                            <Link
                                to={userProfile ? '/' : '/login'}
                                aria-label="OnlyPuns"
                                title="OnlyPuns"
                                className="inline-flex items-center"
                            >
                                {' '}
                            </Link>
                            <Link
                                to={'/submit'}
                                aria-label="submit"
                                title="submit"
                                className="inline-flex items-center"
                            >
                                Submit puns
                            </Link>
                            <Link
                                to={'/about'}
                                aria-label="about"
                                title="about"
                                className="inline-flex items-center"
                            >
                                About
                            </Link>
                        </div>
                        <div className="lg:hidden">
                            <button
                                aria-label="Open Menu"
                                title="Open Menu"
                                className="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-gray-50 focus:bg-deep-gray-50"
                                onClick={() => setIsMenuOpen(true)}
                            >
                                <svg
                                    className="w-5 text-gray-600"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                                    />
                                    <path
                                        fill="currentColor"
                                        d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                                    />
                                    <path
                                        fill="currentColor"
                                        d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                                    />
                                </svg>
                            </button>
                            {isMenuOpen && (
                                <div className="absolute top-0 left-0 w-full bg-primary-400">
                                    <div className="p-5 bg-white border rounded shadow-sm">
                                        <div className="flex items-center justify-between mb-4">
                                            <div>
                                                <Link
                                                    to={'/'}
                                                    aria-label="OnlyPuns"
                                                    title="OnlyPuns"
                                                    className="inline-flex items-center"
                                                >
                                                    <svg
                                                        className="w-8 text-deep-gray-accent-400"
                                                        viewBox="0 0 24 24"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeMiterlimit="10"
                                                        stroke="currentColor"
                                                        fill="none"
                                                    >
                                                        <rect
                                                            x="3"
                                                            y="1"
                                                            width="7"
                                                            height="12"
                                                        />
                                                        <rect
                                                            x="3"
                                                            y="17"
                                                            width="7"
                                                            height="6"
                                                        />
                                                        <rect
                                                            x="14"
                                                            y="1"
                                                            width="7"
                                                            height="6"
                                                        />
                                                        <rect
                                                            x="14"
                                                            y="11"
                                                            width="7"
                                                            height="12"
                                                        />
                                                    </svg>
                                                    <span className="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">
                                                        OnlyPuns
                                                    </span>
                                                </Link>
                                            </div>
                                            <div>
                                                <button
                                                    aria-label="Close Menu"
                                                    title="Close Menu"
                                                    className="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                                                    onClick={() =>
                                                        setIsMenuOpen(false)
                                                    }
                                                >
                                                    <svg
                                                        className="w-5 text-gray-600"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <nav>
                                            <ul className="space-y-4">
                                                <li>
                                                    <Link
                                                        to={'/submit'}
                                                        aria-label="submit"
                                                        title="submit"
                                                        className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-gray-accent-400"
                                                    >
                                                        Submit puns
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to={'/about'}
                                                        aria-label="Setting"
                                                        title="Setting"
                                                        className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-gray-accent-400"
                                                    >
                                                        About
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to={'/'}
                                                        className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-black transition duration-200 rounded shadow-md bg-deep-gray-accent-400 hover:bg-deep-gray-accent-700 focus:shadow-outline focus:outline-none"
                                                        aria-label="Sign up"
                                                        title="Sign up"
                                                    >
                                                        Sign up
                                                    </Link>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
    return (
        <div className="bg-gray-100">
            <nav className="py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
                <div className="relative flex items-center justify-between">
                    <Link
                        to={userProfile ? 'yourdistance' : '/login'}
                        aria-label="OnlyPuns"
                        title="OnlyPuns"
                        className="inline-flex items-center"
                    >
                        <svg
                            className="w-8 text-deep-gray-accent-400"
                            viewBox="0 0 24 24"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            stroke="currentColor"
                            fill="none"
                        >
                            <rect x="3" y="1" width="7" height="12" />
                            <rect x="3" y="17" width="7" height="6" />
                            <rect x="14" y="1" width="7" height="6" />
                            <rect x="14" y="11" width="7" height="12" />
                        </svg>
                        <span className="ml-2 text-xl font-bold tracking-wide text-orange-900">
                            OnlyPuns
                        </span>
                    </Link>
                    <div className="flex items-center hidden space-x-8 lg:flex">
                        <Link
                            to={'/about'}
                            aria-label="about"
                            title="about"
                            className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-gray-400"
                        >
                            About
                        </Link>
                        <Link
                            to={'/submit'}
                            aria-label="Submit pun"
                            title="Submit pun"
                            className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-gray-400"
                        >
                            Submit pun
                        </Link>
                        <Link
                            to={'/'}
                            aria-label="Sign up"
                            title="Sign up"
                            className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-orange-900 transition duration-200 rounded shadow-md bg-deep-gray-accent-400 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
                        >
                            Sign up
                        </Link>
                    </div>
                    <div className="lg:hidden">
                        <button
                            aria-label="Open Menu"
                            title="Open Menu"
                            className="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-gray-50 focus:bg-deep-gray-50"
                            onClick={() => setIsMenuOpen(true)}
                        >
                            <svg
                                className="w-5 text-gray-600"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    fill="currentColor"
                                    d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                                />
                                <path
                                    fill="currentColor"
                                    d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                                />
                                <path
                                    fill="currentColor"
                                    d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                                />
                            </svg>
                        </button>
                        {isMenuOpen && (
                            <div className="absolute top-0 left-0 w-full bg-primary-400">
                                <div className="p-5 bg-white border rounded shadow-sm">
                                    <div className="flex items-center justify-between mb-4">
                                        <div>
                                            <Link
                                                to={'/login'}
                                                aria-label="OnlyPuns"
                                                title="OnlyPuns"
                                                className="inline-flex items-center"
                                            >
                                                <svg
                                                    className="w-8 text-deep-gray-accent-400"
                                                    viewBox="0 0 24 24"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeMiterlimit="10"
                                                    stroke="currentColor"
                                                    fill="none"
                                                >
                                                    <rect
                                                        x="3"
                                                        y="1"
                                                        width="7"
                                                        height="12"
                                                    />
                                                    <rect
                                                        x="3"
                                                        y="17"
                                                        width="7"
                                                        height="6"
                                                    />
                                                    <rect
                                                        x="14"
                                                        y="1"
                                                        width="7"
                                                        height="6"
                                                    />
                                                    <rect
                                                        x="14"
                                                        y="11"
                                                        width="7"
                                                        height="12"
                                                    />
                                                </svg>
                                                <span className="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">
                                                    OnlyPuns
                                                </span>
                                            </Link>
                                        </div>
                                        <div>
                                            <button
                                                aria-label="Close Menu"
                                                title="Close Menu"
                                                className="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                                                onClick={() =>
                                                    setIsMenuOpen(false)
                                                }
                                            >
                                                <svg
                                                    className="w-5 text-gray-600"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="space-y-4">
                                        <Link
                                            to={'/about'}
                                            aria-label="about"
                                            title="about"
                                            className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-gray-accent-400"
                                        >
                                            About
                                        </Link>
                                        <Link
                                            to={'/submit'}
                                            aria-label="Submit pun"
                                            title="Submit pun"
                                            className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-black transition duration-200 rounded shadow-md bg-deep-gray-accent-400 hover:bg-deep-gray-accent-700 focus:shadow-outline focus:outline-none"
                                        >
                                            Submit pun
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </nav>
        </div>
    );
};
export default Navbar;
