import React from 'react'

const header = (text) => {
    return (

        <h1 className="text-base font-bold tracking-wide text-gray-900 mt-4">{text}</h1>
    );
}

const Terms = () => {
    return (
        <div className="m-10">
            <div className="mt-5">
                {header('Terms & Conditions')}
                <p>OnyPuns is not a for profit site so we try and respect you privacy without selling your data and limiting any third party tools.</p>
                <p>This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website onlypuns.xyz, including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the “Site”). Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site</p>
                <p>We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will alert you about any changes by updating the “Last Updated” date of this Privacy Policy. Any changes or modifications will be effective immediately upon posting the updated Privacy Policy on the Site, and you waive the right to receive specific notice of each such change or modification.</p>
                {header('Information Collection and Use')}
                <p>We try to limit any personal identifiable information, however we do store non personal identifiable information like a user token, user Id and generated puns</p>
                <p>Information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system and your access times.</p>
                {header('Information Sharing')}
                <p>We do not share your information with third parties. However, your information may be disclosed as follows: By Law or to Protect Rights</p>
                <p>If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation. This includes exchanging information with other entities for fraud protection and credit risk reduction. Internet-Based Advertising</p>
                <p>Additionally, we may use third-party software to serve ads on the Site, implement email marketing campaigns, and manage other interactive marketing initiatives. This third-party software may use cookies or similar tracking technology to help manage and optimise your online experience with us. For more information about opting-out of interest-based ads, visit the Network Advertising Initiative Opt-Out Tool or Digital Advertising Alliance Opt-Out Tool. Website Analytics</p>
                <p>We may also partner with selected third-party vendors, such as Google Analytics to allow tracking technologies and marketing services on the Site through the use of first party cookies and third-party cookies, to, among other things, analyse and track users’ use of the Site, determine the popularity of certain content and better understand online activity. By accessing the Site, you consent to the collection and use of your information by these third-party vendors. You are encouraged to review their privacy policy and contact them directly for responses to your questions. We do not transfer personal information to these third-party vendors. However, if you do not want any information to be collected and used by tracking technologies, you can visit the third-party vendor or the Network Advertising Initiative Opt-Out Tool or Digital Advertising Alliance Opt-Out Tool.</p>
                {header('Third Party websites')}
                <p>OnlyPuns will link out to Strava to authenticate using OAuth 2.0, Strava handles your username and password and does not share it with OnlyPuns. If  you wish to revoke the access you can access your linked apps at <a className="text-gray-600 transition-colors duration-300 hover:text-orange-accent-400"
                    href='https://www.strava.com/settings/apps'>https://www.strava.com/settings/apps</a></p>
                {header('Security')}
                <p>We try and take the best standards to help protect the data we store. Please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse. Any information disclosed online is vulnerable to interception and misuse by unauthorised parties. Therefore, we cannot guarantee complete security if you provide personal information.</p>
                <p>Please if you do find a security vulnerability, please email us at onlypunsxyz@gmail.com</p>
            </div>

            <footer className='text-gray-500'>Last updated: 1 May 2022</footer>

        </div>
    )
}

export default Terms





