import React, { createContext, useContext, useState } from 'react';
import {
    Redirect,
    Route,
    BrowserRouter as Router,
    Switch,
} from 'react-router-dom';

import About from '../pages/About';
import Faq from '../pages/FAQ';
import Footer from '../components/Footer';
import Home from '../pages/Home';
import Navbar from '../components/Navbar';
import NotFound from '../pages/NotFound';
import StravaRedirect from '../pages/StravaRedirect';
import SubmitPun from '../pages/SubmitPun';
import YourDistance from '../pages/YourDistance';
import YourDistanceDemo from '../pages/YourDistanceDemo';
import Terms from '../pages/Terms';

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */
const authContext = createContext();

function ProvideAuth({ children }) {
    const localStore = window.localStorage.getItem('onlyPuns');
    const userData = JSON.parse(localStore);
    const isAuthenticated = userData && userData.a;

    return (
        <authContext.Provider value={isAuthenticated}>
            {children}
        </authContext.Provider>
    );
}

function useAuth() {
    return useContext(authContext);
}

function PrivateRoute({ children, ...rest }) {
    const localStore = window.localStorage.getItem('onlyPuns');
    const userData = JSON.parse(localStore);
    const isAuthenticated = userData && userData.a;

    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAuthenticated ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}

const AppRouter = () => {
    return (
        <div className="main">
            <ProvideAuth>
                <Router>
                    <Navbar />
                    <Switch>
                        <Route path="/login" exact={true}>
                            <Home />
                        </Route>
                        <Route path="/redirect/:access_token/:id/:expiry">
                            <StravaRedirect />
                        </Route>
                        <Route path="/demo">
                            <YourDistanceDemo />
                        </Route>
                        <Route path="/about">
                            <About />
                        </Route>
                        <Route path="/faq">
                            <Faq />
                        </Route>
                        <Route path="/terms">
                            <Terms />
                        </Route>
                        <PrivateRoute path="/submit">
                            <SubmitPun />
                        </PrivateRoute>
                        <PrivateRoute exact path="/">
                            <YourDistance />
                        </PrivateRoute>
                        <Route>
                            <NotFound />
                        </Route>
                    </Switch>
                    <Footer />
                </Router>
            </ProvideAuth>
        </div>
    );
};
export default AppRouter;
