import React from 'react';

export default function punSuggestionList(suggestedPuns, votForPun) {
    if (suggestedPuns && suggestedPuns[0]) {
        return suggestedPuns.map((item, index) => {
            return (
                <li
                    className={
                        'list-none  m-1 border-2 ' +
                        (item.selected ? 'bg-orange-400' : 'bg-gray-400')
                    }
                    key={item.id}
                    onClick={() => votForPun(item.punId)}
                >
                    <button className="m-4 w-12/12" data-id={item.punId}>
                        {`${item.pun}`}
                    </button>
                </li>
            );
        });
    }
}
