import { Header } from '../components/Header';
import React from 'react';
import { Step } from '../components/Step';

import ridePun from '../assets/phone/ridePun.png';
import runPun from '../assets/phone/runPun.png';
import runPunStrava from '../assets/phone/runPunStrava.png';

// can be set in Cloudflare
const { REACT_APP_CLIENT_ID, REACT_APP_SCOPE, REACT_APP_CALLBACK_URL } = process.env;



const handleLogin = () => {
    window.location = `https://www.strava.com/oauth/authorize?client_id=${REACT_APP_CLIENT_ID}&response_type=code&redirect_uri=${REACT_APP_CALLBACK_URL}&approval_prompt=force&scope=${REACT_APP_SCOPE}`;
};

const handleDemoLogin = () => {
    window.location = 'demo';
};

const Home = () => {
    return (
        <div>
            <Header
                handleLoginPress={handleLogin}
                handleDemoPress={handleDemoLogin}
            />
            <Step />
            <div className='center'>
                <div className='flex items-center justify-center'>
                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                    Examples of OnlyPuns in action</h2>
                    </div>
                <div className="flex  md:items-center  md:justify-center  md:mx-20">
                    <div className="md:flex  md:row  md:justify-between">
                        <div className="w-68">
                            <img src={runPun} alt="strava" />
                        </div>
                        <div className="w-68">
                            <img src={ridePun} alt="strava" />
                        </div>
                        <div className="w-68">
                            <img src={runPunStrava} alt="strava" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
