import React from 'react';

const Footer = () => {
    return (
        <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
            <div>
                <span className="text-base font-bold tracking-wide text-gray-900">
                    Social
                </span>
                <div className="flex items-center mt-1 space-x-3">
                    <a
                        href="https://www.instagram.com/onlypuns.xyz"
                        className="text-gray-500 transition-colors duration-300 hover:text-red-accent-400"
                    >
                        <svg
                            viewBox="0 0 30 30"
                            fill="currentColor"
                            className="h-6"
                        >
                            <circle cx="15" cy="15" r="4" />
                            <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
                        </svg>
                    </a>
                </div>
                <p className="mt-4 text-sm text-gray-500">
                    Joining the OnlyPuns community on Instagram and share your
                    fav puns.
                </p>
            </div>
            <div className="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row">
                <p className="text-sm text-gray-600">{`Made with ☕  `+ `  and 🍩`}</p>
                <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
                    <li>
                        <a
                            href="/faq"
                            className="text-sm text-gray-600 transition-colors duration-300 hover:text-red-accent-400"
                        >
                            F.A.Q
                        </a>
                    </li>
                    <li>
                        <a
                            href="/terms"
                            className="text-sm text-gray-600 transition-colors duration-300 hover:text-red-accent-400"
                        >
                            Terms &amp; Conditions
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Footer;
