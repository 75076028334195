import React from 'react'

const NotFound = () => {
    return (
        <div className="d-flex justify-content-center align-items-center text-center content-body">
            <div className="mt-5">
                <p className="purple-text">Sorry, it looks like something went wrong.</p>
            </div>
        </div>
    )
}

export default NotFound