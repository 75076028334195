import React, { useCallback, useEffect, useState } from 'react';
import { getSuggestedPuns, voteForPunApi } from '../utils/functions';

import PunForm from '../components/PunForm';
import Submit from '../assets/submit.jpeg';
import punSuggestionList from '../components/punSuggestionList';

const SubmitPun = () => {
    const [suggestedPuns, setSuggestedPuns] = useState();
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    useEffect(async () => {
        const res = await getSuggestedPuns();
        if (res.data?.data) {
            res.data?.data.map((punItem) => {
                punItem.selected = false;
            });
            setSuggestedPuns(res.data?.data);
        }
    }, []);

    const votForPun = (punItemID) => {
        suggestedPuns.map((pun) => {
            if (pun.punId === punItemID) {
                let temp_element = { ...pun };
                if (temp_element.selected !== true) {
                    temp_element.selected = true;
                    suggestedPuns[temp_element.punId] = temp_element;
                    voteForPunApi(temp_element.punId);
                }
            }
        });
        setSuggestedPuns(suggestedPuns);
        forceUpdate();
    };

    if (suggestedPuns) {
        return (
            <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                <div className="mb-12 lg:pr-5 lg:mb-0">
                    <div className="mb-6">
                        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                            Submit a{' '}
                            <span className="inline-block text-orange-900">
                                pun
                            </span>
                            <br className="hidden md:block" />
                        </h2>
                        <PunForm />
                    </div>
                    <hr className="mb-6 border-gray-300" />
                    <h2 className="mb-4 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                        Vote for your{' '}
                        <span className="inline-block text-orange-900">
                            favorite
                        </span>{' '}
                        puns
                        <br className="hidden md:block" />
                    </h2>
                    <label
                        class="block text-gray-500 font-bold mb-1 md:mb-0 pr-4 ml-2"
                        for="inline-your-pun"
                    >
                        Click to cast your vote
                    </label>
                    {punSuggestionList(suggestedPuns, votForPun)}
                </div>
                <img
                    className="hero max-w-screen-lg mx-auto pb-10"
                    src={Submit}
                    alt="Cartoon person on laptop"
                />
            </div>
        );
    } else {
        return <div />;
    }
};

export default SubmitPun;
