import React from 'react';

const CircularProfilePic = ({ imageURL }) => {
    return (
        <div className="w-52">
            <img
                src={imageURL}
                alt="profile"
                className="rounded-full shadow-lg"
            />
        </div>
    );
};

export default CircularProfilePic;
