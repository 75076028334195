import React, { useState } from 'react';

import { submitPun } from '../utils/functions';

const PunForm = () => {
    const [punValue, setPunValue] = useState('');

    const handleChange = (event) => {
        setPunValue(event.target.value);
    };

    const handleSubmit = async (punSuggestion) => {
        const suggestionResponse = await submitPun(punSuggestion);
        setPunValue('');
    };
    return (
        <form class="w-full">
            <div class="w-full">
                <label
                    class="block text-gray-500 font-bold mb-1 md:mb-0 pr-4"
                    for="inline-your-pun"
                >
                    Your pun
                </label>
            </div>
            <div class="md:flex md:items-center mb-6">
                <div class="md:w-full">
                    <textarea
                        class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-red-500 "
                        id="inline-your-pun"
                        type="text"
                        onChange={handleChange}
                        value={punValue}
                    ></textarea>
                </div>
            </div>

            <div class="md:flex md:items-center">
                <div class="md:w-1/3"></div>
                
            </div>
            <div class="md:w-2/3">
                    <button
                        class="shadow bg-red-500 hover:bg-red-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                        type="button"
                        onClick={() => handleSubmit(punValue)}
                    >
                        Submit pun!
                    </button>
                </div>
        </form>
    );
};

export default PunForm;
