import { React, useEffect, useState } from 'react';
import { convertToMiles, getUserDetails, getUserStats } from '../utils/functions';
import { setUserActivities, setUserProfile } from '../actions';
import { useDispatch, useSelector } from 'react-redux';

import BlockList from '../components/BlockList';

const getUserData = async (setLoading, userData, dispatch) => {
    const userActivities = await getUserStats(userData.b, userData.a);
    const userDetails = await getUserDetails(userData.b, userData.a);

    if (userActivities && userActivities.data && userDetails.data) {
        dispatch(setUserProfile(userDetails.data));

        dispatch(
            setUserActivities({
                runTotal: {
                    kms: (
                        userActivities.data.all_run_totals.distance / 1000
                    ).toFixed(2),
                    miles: convertToMiles(
                        userActivities.data.all_run_totals.distance,
                    ).toFixed(2),
                    count: userActivities.data.all_run_totals.count,
                },
                rideTotal: {
                    kms: (
                        userActivities.data.all_ride_totals.distance / 1000
                    ).toFixed(2),
                    miles: convertToMiles(
                        userActivities.data.all_ride_totals.distance,
                    ).toFixed(2),
                    count: userActivities.data.all_ride_totals.count,
                },
                swimTotal: {
                    kms: (
                        userActivities.data.all_swim_totals.distance / 1000
                    ).toFixed(2),
                    miles: convertToMiles(
                        userActivities.data.all_swim_totals.distance,
                    ).toFixed(2),
                    count: userActivities.data.all_swim_totals.count,
                },
            }),
        );
        setLoading(false);
    }
};

const YourDistance = () => {
    const [loading, setLoading] = useState(true);
    const localStore = window.localStorage.getItem('onlyPuns');
    const dispatch = useDispatch();

    useEffect(() => {
        getUserData(setLoading, JSON.parse(localStore), dispatch);
    }, []);

    if (loading) {
        return <div className="container p-0"></div>;
    }

    return (
        <div className="container p-0">
            <BlockList />
        </div>
    );
};

export default YourDistance;
