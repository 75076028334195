import React from 'react';

const InfoImageBlock = ({
    imageSRC,
    text,
    number,
    usingMetric,
    kms,
    miles,
}) => {
    const totalDistance = usingMetric ? kms : miles;

    return (
        <div className="relative">
            <img
                src={imageSRC}
                alt="earth"
                className="infoBlockImg rounded-2xl shadow-lg"
            ></img>
            <div className="flex flex-col justify-center bg-white lg:pl-10 lg:w-1/2">
                <h5 className="mb-3 text-3xl font-extrabold leading-none sm:text-4xl">
                    {number}{" - "}{text}
                </h5>
                <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                    {totalDistance}
                </p>
            </div>
        </div>
    );
};

export default InfoImageBlock;
