import React, { useEffect } from 'react';
import { checkSubscriptions, subscribeToWebhook } from '../utils/functions';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import Loading from '../components/Loading';
import _ from 'lodash';
import { setEnrolledStatus } from '../actions';
import { useDispatch } from 'react-redux';
import { useLocalStorage } from '../utils/hooks';

const authenticate = async (
    location,
    history,
    setUserData,
    access_token,
    id,
    expiry,
    dispatch,
) => {
    try {
        // If not redirected to Strava, return to home
        if (_.isEmpty(location)) {
            return history.push('/');
        }
        setUserData({ a: access_token, b: id, e: expiry });
        // Post Request to Strava (with AuthToken) which returns Refresh Token and and Access Token
        // const responseTokens = await get(auth);

        let activeSubscriptions = await checkSubscriptions();
        if (activeSubscriptions.length === 0) {
            activeSubscriptions = await subscribeToWebhook();
        }
        console.log(
            'activeSubscriptions.length > 0',
            activeSubscriptions.length > 0,
        );
        if (activeSubscriptions.length > 0) {
            console.log('got here');
            dispatch(setEnrolledStatus(true));
        }
        history.push('/');
    } catch (error) {
        history.push('/login');
    }
};

const StravaRedirect = (props) => {
    const [userData, setUserData] = useLocalStorage('onlyPuns', '');
    let { access_token, id, expiry } = useParams();
    let location = useLocation();
    let history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        authenticate(
            location,
            history,
            setUserData,
            access_token,
            id,
            expiry,
            dispatch,
        );
    }, []);
    return (
        <div>
            <Loading text={'Talking to Strava.'} />
        </div>
    );
};

export default StravaRedirect;
