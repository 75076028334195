import React from 'react';
import Simon from '../assets/simon.png';

export const Team = () => {
    return (
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="mx-auto mb-10 lg:max-w-xl sm:text-center lg:back">
                <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                    Meet the team
                </p>
                <p className="text-base text-gray-700 md:text-lg">
                    We welcome collaboration, so please if you would like to get
                    involved please make contact.
                </p>
            </div>
            <div className="grid gap-10 mx-auto lg:grid-cols-2 lg:max-w-screen-lg">
                <div className="grid sm:grid-cols-3">
                    <div className="relative w-full h-48 max-h-full rounded shadow sm:h-auto">
                        <img
                            className="absolute object-cover w-full h-full rounded"
                            src={Simon}
                            alt="Person"
                        />
                    </div>
                    <div className="flex flex-col justify-center mt-5 sm:mt-0 sm:p-5 sm:col-span-2">
                        <p className="text-lg font-bold">Simon</p>
                        <p className="mb-4 text-xs text-gray-800">Tech guy</p>
                        <p className="mb-4 text-sm tracking-wide text-gray-800">
                            I will not fix your printer! Okay fine if you ask
                            nicely. Mostly on my bicycle or on the trails.
                        </p>
                        <div className="flex items-center space-x-3">
                            <a
                                href="https://www.instagram.com/simonluke"
                                className="text-gray-600 transition-colors duration-300 hover:text-red-accent-400"
                            >
                                <svg
                                    viewBox="0 0 30 30"
                                    fill="currentColor"
                                    className="h-6"
                                >
                                    <circle cx="15" cy="15" r="4" />
                                    <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
                                </svg>
                            </a>
                            <a
                                href="https://www.strava.com/athletes/1170801"
                                className="text-gray-600 transition-colors duration-300 hover:text-red-accent-400"
                            >
                                <svg
                                    viewBox="0 0 64 64"
                                    fill="currentColor"
                                    className="h-5"
                                >
                                    <path d="M41.03 47.852l-5.572-10.976h-8.172L41.03 64l13.736-27.124h-8.18" />
                                    <path d="M27.898 21.944l7.564 14.928h11.124L27.898 0 9.234 36.876H20.35" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="grid sm:grid-cols-3">
                    <div className="relative w-full h-48 max-h-full rounded shadow sm:h-auto">
                        <img
                            className="absolute object-cover w-full h-full rounded"
                            src="https://images.squarespace-cdn.com/content/v1/59b17738ccc5c56aaec50db0/1624473708141-833X95A3LBNS9ZMMRPNC/image-asset.jpg"
                            alt="Person"
                        />
                    </div>
                    <div className="flex flex-col justify-center mt-5 sm:mt-0 sm:p-5 sm:col-span-2">
                        <p className="text-lg font-bold">Tegan</p>
                        <p className="mb-4 text-xs text-gray-800">
                            Creative magician
                        </p>
                        <p className="mb-4 text-sm tracking-wide text-gray-800">
                            I work full-time as a cartoonist, speaker and
                            endurance cyclist with team Wintergreen Barrier
                            Breakers. I am currently working on a book and
                            training to set a women’s record of the fastest
                            cycle from Cairo to Cape Town in Feb 2022.
                        </p>
                        <div className="flex items-center space-x-3">
                            <a
                                href="https://www.instagram.com/teganphillipscomics"
                                className="text-gray-600 transition-colors duration-300 hover:text-red-accent-400"
                            >
                                <svg
                                    viewBox="0 0 30 30"
                                    fill="currentColor"
                                    className="h-6"
                                >
                                    <circle cx="15" cy="15" r="4" />
                                    <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
                                </svg>
                            </a>
                            <a
                                href="https://www.strava.com/athletes/6925775?oq=tega"
                                className="text-gray-600 transition-colors duration-300 hover:text-red-accent-400"
                            >
                                <svg
                                    viewBox="0 0 64 64"
                                    fill="currentColor"
                                    className="h-5"
                                >
                                    <path d="M41.03 47.852l-5.572-10.976h-8.172L41.03 64l13.736-27.124h-8.18" />
                                    <path d="M27.898 21.944l7.564 14.928h11.124L27.898 0 9.234 36.876H20.35" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const About = () => {
    return (
        <div className="black-body d-flex justify-content-center align-items-center content-body text-center content-body">
            <Team />
            <h5 className="mb-5 black-body whiteish">
                Strava's full Privacy Policy, which covers approved third-party
                projects like this one, is available{' '}
                <a
                    className="text-red-500"
                    href="https://www.strava.com/legal/privacy"
                >
                    here
                </a>
                .{' '}
            </h5>
        </div>
    );
};

export default About;
