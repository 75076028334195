import axios from 'axios';

const { REACT_APP_CLIENT_ID, REACT_APP_CLIENT_SECRET, REACT_APP_WEBHOOKURL } =
    process.env;

export const getParamValues = (url) => {
    return url
        .slice(1)
        .split('&')
        .reduce((prev, curr) => {
            const [title, value] = curr.split('=');
            prev[title] = value;
            return prev;
        }, {});
};

export const cleanUpAuthToken = (str) => {
    return str.split('&')[1].slice(5);
};

export const checkSubscriptions = async () => {
    try {
        const response = await axios.get(
            `https://www.strava.com/api/v3/push_subscriptions?client_id=${REACT_APP_CLIENT_ID}&client_secret=${REACT_APP_CLIENT_SECRET}`,
        );
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const subscribeToWebhook = async () => {
    try {
        const response = await axios.post(
            `https://www.strava.com/api/v3/push_subscriptions?client_id=${REACT_APP_CLIENT_ID}&client_secret=${REACT_APP_CLIENT_SECRET}&callback_url=${REACT_APP_WEBHOOKURL}&verify_token=STRAVA`,
        );
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const auth = async () => {
    try {
        const response = await axios.get(`https://serverroom.info/auth`, {
            headers: { 'Access-Control-Allow-Origin': '*' },
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const submitPun = async (punSuggestion) => {
    try {
        const response = await axios.post(
            `https://serverroom.info/addPunSuggestion`,
            {
                suggestion: punSuggestion,
            },
        );

        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const voteForPunApi = async (punId) => {
    try {
        const response = await axios.post(
            `https://serverroom.info/voteForPun`,
            {
                id: punId,
            },
        );
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const getSuggestedPuns = async () => {
    try {
        const response = await axios.get(
            `https://serverroom.info/getPunsForRanking`,
        );
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const getUserStats = async (userID, accessToken) => {
    try {
        const response = await axios.get(
            `https://www.strava.com/api/v3/athletes/${userID}/stats`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        );
        return response;
    } catch (error) {
        window.location = `/login`;
        console.log('error', error);
    }
};

export const getUserDetails = async (userID, accessToken) => {
    try {
        const response = await axios.get(
            `https://www.strava.com/api/v3/athletes/${userID}`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        );
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const convertToMiles = (meters) => {
    return (meters * 0.621371) / 1000;
};

// Distance Functions

export const trackLaps = (kms) => {
    return fixNumber(kms * 2.5);
};

export const poolLaps = (kms) => {
    return fixNumber(kms * 20);
};

export const earthLaps = (kms) => {
    const earthCircumfrence = 40075; // In kms
    const result = (kms / earthCircumfrence) * 100;
    return fixNumber(result) + '%';
};

export const burjLaps = (kms) => {
    const burjHeight = 0.83 * 2; // Up and down
    const result = kms / burjHeight;
    return fixNumber(result);
};

export const tourLaps = (kms) => {
    const tourLength = 3470;
    const result = kms / tourLength;
    return fixNumber(result);
};

export const mountEverestLaps = (kms) => {
    const mountEverestHeight = 8.849;
    const result = kms / mountEverestHeight;
    return fixNumber(result);
};

export const americaLaps = (kms) => {
    const americaLength = 4662;
    const result = kms / americaLength;
    return fixNumberTwoDigits(result);
};

export const southKoreaLaps = (kms) => {
    const southKoreaLength = 1200;
    const result = kms / southKoreaLength;
    return fixNumberTwoDigits(result);
};

export const moonLaps = (kms) => {
    const moonDistance = 384400;
    const result = (kms / moonDistance) * 100;
    return fixNumberTwoDigits(result) + '%';
};

export const cape2CairoLaps = (kms) => {
    const cape2Cairo = 10228;
    const result = kms / cape2Cairo;
    return fixNumberTwoDigits(result);
};

const fixNumber = (x) => {
    return x
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const fixNumberTwoDigits = (x) => {
    return x
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
