import CircularProfilePic from './CircularProfilePic';
import { React } from 'react';
import blankAvatar from '../assets/blankAvatar.jpeg';
import { useSelector } from 'react-redux';

const HeaderBlock = () => {
    const userProfile = useSelector((state) => state.userProfile);
    const enrolment = useSelector((state) => state.enrolledForOnlyPuns);

    const profileImage = userProfile.profile || blankAvatar;
    return (
        <div className="mt-5 ">
            <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                <div className="max-w-screen-sm sm:text-center sm:mx-auto">
                    <a
                        href="/"
                        aria-label="View"
                        className="inline-block mb-5 rounded-full sm:mx-auto"
                    >
                        <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50">
                            <CircularProfilePic imageURL={profileImage} />
                        </div>
                    </a>
                    <h2 className="mb-4 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                        {`Hey ${userProfile.firstname}`}
                    </h2>
                    <p className="text-base text-gray-700 md:text-lg sm:px-4">
                        {enrolment
                            ? 'You are enrolled for onlyPun'
                            : ''}
                    </p>
                    <p className="text-base text-gray-700 md:text-lg sm:px-4">
                        Here are your stats...
                    </p>
                </div>
            </div>
        </div>
    );
};

export default HeaderBlock;
